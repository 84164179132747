import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('pie-chart')

export default function BarChart ({ items }) {
  const total = items.reduce((results, item) => results + item.value, 0)

  const getCoordinates = percent => {
    const x = Math.cos(2 * Math.PI * percent)
    const y = Math.sin(2 * Math.PI * percent)

    return [x, y]
  }

  let cumulativePercent = 0
  const getPercentage = value => (value * 100) / total

  return (
    <figure {...bem('')}>
      <svg viewBox="-1 -1 2 2" {...bem('svg')}>
        {items
          .sort((a, b) => b.value - a.value)
          .map(({ value, name, type, color }) => {
            const percent = getPercentage(value) / 100

            const [startX, startY] = getCoordinates(cumulativePercent)
            cumulativePercent += percent
            const [endX, endY] = getCoordinates(cumulativePercent)

            // If larger than 50%
            const largeArcFlag = percent > 0.5 ? 1 : 0

            const path = `M ${startX} ${startY}
              A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}
              L 0 0`

            return (
              <path
                d={path}
                key={name}
                {...bem('slice', type)}
                style={color ? { fill: color } : {}}
              />
            )
          })}
      </svg>
    </figure>
  )
}

BarChart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number.isRequired,
      type: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
}

// https://hackernoon.com/a-simple-pie-chart-in-svg-dbdd653b6936
