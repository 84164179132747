import './index.scss'

import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import imageEmailSignature from '../../assets/brandbook/images/signature_logo.gif'

import Icon from '../Icon'

const bem = new BEMHelper('brandbook')

export function Color ({ color, name, cmyk, pantone, rgb, hex, tones }) {
  return (
    <div {...bem('color')}>
      <h3 {...bem('color-name')}>{name}</h3>

      <div {...bem('color-description')}>
        <div {...bem('color-example', ['large', color])} />

        <ul {...bem('color-list')}>
          <li>Pantone {pantone}</li>
          <li>CMYK: {cmyk}</li>
          <li>RGB: {rgb}</li>
          <li>HEX: {hex}</li>
        </ul>
      </div>

      {tones &&
        tones.map(tone => (
          <div {...bem('color-example', color)} key={tone}>
            <span {...bem('color-tone')}>{tone}%</span>
          </div>
        ))}
    </div>
  )
}

export function IconList ({ icons }) {
  return (
    <ul {...bem('list')}>
      {icons.map(icon => (
        <li {...bem('list-icon')} key={icon}>
          <Icon icon={icon} key={icon} />
        </li>
      ))}
    </ul>
  )
}

export function EmailSignature () {
  return (
    <div {...bem('signature')}>
      <p {...bem('signature-text')}>
        Regards
        <br />
        <br />
        <strong contentEditable>FIRSTNAME LASTNAME</strong>
        <br />
        <em contentEditable>Job title</em>
        <br />
        <br />
        Direct: <span contentEditable>+47 00 00 00 00</span>
        <br />
        Mobile: <span contentEditable>+47 000 00 000</span>
        <br />
        www.ncl.no
        <br />
        <br />
        Møllerveien 22, PO. Box 291
        <br />
        N-5501 Haugesund
        <br />
        <br />
        <img src={imageEmailSignature} {...bem('signature-logo')} width="240" />
        <br />
        <br />
      </p>

      <p {...bem('signature-disclaimer')} style={{ fontSize: '12px' }}>
        The information contained in this message may be CONFIDENTIAL and is
        intended for the addressee only. Any unauthorized use, dissemination of
        the information or copying of this message is prohibited. If you are not
        the addressee, please notify the sender immediately by return e-mail and
        delete this message. Thank you!
      </p>
    </div>
  )
}

IconList.propTypes = {
  icons: PropTypes.array.isRequired,
}
