import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Hero from '../../components/Hero'
import Article, { Text, Title } from '../../components/Article'
import ContactCard from '../../components/ContactCard'
import PieChart from '../../components/PieChart'
import Radio from '../../components/Radio'

import { Color } from '../../components/Brandbook'
import Section from '../../components/Section'

export default class Colors extends Component {
  state = {
    chart: 'internal',
  }

  handleChange = key => ({ target: { value } }) => {
    this.setState({
      [key]: value,
    })
  }

  render() {
    const {
      data: { hero },
    } = this.props

    const { chart } = this.state

    return (
      <>
        <Hero {...hero} light title="Colors" />

        <Article>
          <Title level="2">Primary palette</Title>
          <Text>
            Our primary color palette is made up of three primary colors. Blue,
            Green and Yellow. Each of them represent NCL as a company - past,
            present and future.
          </Text>
          <Color
            name="NCL Blue"
            color="blue"
            pantone="534 C"
            cmyk="87% 43% 0% 65%"
            rgb="12 51 89"
            hex="#0C3359"
          />

          <Color
            name="NCL Green"
            color="green"
            pantone="621 C"
            cmyk="16% 0% 8% 7%"
            rgb="198 237 218"
            hex="#C6EDDA"
          />
          <Color
            name="NCL Yellow"
            color="yellow"
            pantone="7548 C"
            cmyk="0% 19% 100% 0%"
            rgb="255 207 0"
            hex="#FFCF00"
          />

          <Color
            name="White"
            color="white"
            pantone="7548 C"
            cmyk="100% 100% 100% 100%"
            rgb="255 255 255"
            hex="#FFFFFF"
          />

          <Title level="2">Secondary palette</Title>

          <Color
            name="NCL Green 2"
            color="green2"
            pantone="X"
            cmyk="X"
            rgb="198 237 218"
            hex="#3b9e63"
          />

          <Color
            name="NCL Green 3"
            color="green3"
            pantone="X"
            cmyk="X"
            rgb="198 237 218"
            hex="#ACE5C5"
          />

          <Color
            name="NCL Green 4"
            color="green4"
            pantone="X"
            cmyk="X"
            rgb="198 237 218"
            hex="#eff4f1"
          />

          <Title level="2">Technical usage</Title>
          <Text>
            There are various color systems for different outputs, and the
            production of physical colors and display of color in digital
            channels uses different technology. The biggest difference is
            between screen colors and analogue colors.
          </Text>
          <Title level="3">Print</Title>
          <Text>
            For printed material, use the Pantone (PMS) colors to get the most
            accurate colors. For print in standard colors, use CMYK.
          </Text>
          <Title level="3">Screen</Title>
          <Text>
            When working with colors for digital media and outputs on screen,
            use RGB or HEX.
          </Text>

          <Title level="2">Contextual usage</Title>
          <Text>
            How much of each color to use is all about context. The chart below
            visualize the recommended amount of each color based on type of
            channel.
          </Text>

          <div>
            <Radio
              name="color-view"
              label="Internal"
              value="internal"
              onChange={this.handleChange('chart')}
              checked={chart === 'internal'}
            />
            <Radio
              name="color-view"
              label="External"
              value="external"
              onChange={this.handleChange('chart')}
              checked={chart === 'external'}
            />
            {chart === 'internal' && (
              <PieChart
                items={[
                  { name: 'Blue', value: 20, color: '#0C3359' },
                  { name: 'Green', value: 10, color: '#C6EDDA' },
                  { name: 'Green 2', value: 1, color: '#3b9e63' },
                  { name: 'Green 3', value: 5, color: '#ACE5C5' },
                  { name: 'Green 4', value: 10, color: '#eff4f1' },
                  { name: 'Yellow', value: 1, color: '#FFCF00' },
                  { name: 'Red', value: 1, color: '#ae2a2a' },
                  { name: 'White', value: 30, color: '#FFFFFF' },
                ]}
              />
            )}

            {chart === 'external' && (
              <PieChart
                items={[
                  { name: 'Blue', value: 30, color: '#0C3359' },
                  { name: 'Green', value: 20, color: '#C6EDDA' },
                  { name: 'Green 2', value: 2, color: '#3b9e63' },
                  { name: 'Green 3', value: 2, color: '#ACE5C5' },
                  { name: 'Green 4', value: 2, color: '#eff4f1' },
                  { name: 'Yellow', value: 10, color: '#FFCF00' },
                  { name: 'White', value: 13, color: '#FFFFFF' },
                ]}
              />
            )}
          </div>
          <br />
          <Text>
            These guidelines follow the same principles as the{' '}
            <Link to="brandbook/logo">NCL logotype</Link>: In our own channels
            (website) where one could expect that people already know who we
            are, we use less yellow and more light colors for a more
            sophisticated look. However, in external channels (advertising)
            where we must build brand awerness and compete for attention we
            should use a wider range of colors.
          </Text>
        </Article>

        <Section>
          <Title>Any questions?</Title>
          <ContactCard who="ada-jakobsen" />
        </Section>
      </>
    )
  }
}

Colors.propTypes = {
  data: PropTypes.object.isRequired,
}

Colors.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/cargooperation/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
